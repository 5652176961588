
:root {
  --type--weight-bold: 700;
  --type--size-xl: 36px;
}

* {
  &:focus {
    outline-width: 0;
  }
}

html, body {
  height: 100%;
  margin: 0px;
}

a {
  // color: #ffb600;
  color: #2E9FFE;

  &:hover {
    text-decoration: underline !important;
    cursor: pointer;
  }
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.round {
  width: 100%;
  margin: auto;
  border-radius: 15px;
}


p {
  word-wrap: break-word;
}

.loading-container {
  width: 100%;
  height: 100vh;
}

.page-title {
  width: 100%;
  margin-left: 0;
  color: black;
  opacity: 1;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
}


.quiz {
  height: 100%;
}

.bold {
  font-weight: 700;
}

.hidden {
  display: none;
}

.gradient {
  background-image: url("src/assets/images/background/gradient-background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.divider-bar {
  width: 75px;
  height: 8px;
  background: transparent linear-gradient(92deg, #FD4B36 0%, #FAAF37 100%) 0% 0% no-repeat padding-box;
  border-radius: 15px;

  &.left {
    margin-left: 0;
  }
}

.button {
  text-align: center;
  border-radius: 50px !important;
  opacity: 1;
  padding: 5px 25px !important;

  &.mat-stroked-button.mat-accent {
    border: 1px solid #2E9FFE;
  }
}

.circle {
  border-radius: 100% !important;
}

.custom-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(32px);
  -webkit-filter: blur(32px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.bubble-background {
  background-image: url("src/assets/images/background/bubble-background.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(32px);
  -webkit-filter: blur(32px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.gray-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;
  background-color: rgb(245, 245, 245);
}

.gray {
  background-color: rgb(245, 245, 245) !important;
}

.card {
  height: 100%;
  width: 100%;
  background: white;
  border-radius: 25px;
  padding: 25px;
}

&.no-padding {
  padding: 0;
}

.raised {
  box-shadow: 0 1px 2.5px 0 rgba(60, 64, 67, 0.2), 0 8.4px 12px -5px rgba(60, 64, 67, 0.05);
}

.bordered {
  border: 0.15px solid rgba(134, 134, 134, 0.31);
}

.success-icon {
  color: green;
}

.flex-container {
  margin: auto;
}

.no-padding {
  padding: 0;
}

.title-container {
  width: 100%;

  &.page-title {

  }

  .title {
    text-align: left;
  }
}

::ng-deep {

  .h1 {
    font-weight: 600;
    font-size: 1.7rem;
  }

  .h2 {
    font-weight: 500;
    font-size: 1.5rem;
  }

  .h3 {
    font-weight: 500;
    font-size: 1.2rem;
  }

  .h4 {
    font-weight: 400;
    font-size: 0.9rem;
    text-transform: uppercase;
  }

  .blue-text {
    color: #4CB4FC;
  }

  .purple-text {
    color: #B060FF;
  }

  .orange-text {
    color: #f54c00;
  }

  .green-text {
    color: #8CCC59;
  }

  .health-symptom {
    color: #f54c00;

    mat-icon {
      transform: scale(0.8);
    }

    span {
      font-weight: 600 !important;
      text-transform: uppercase;
      font-size: 1rem;
      line-height: 1;
      text-align: left;
    }
  }

  .hair-property {
    color: #4CB4FC;

    mat-icon {
      transform: scale(0.8);
    }

    span {
      font-weight: 600 !important;
      text-transform: uppercase;
      font-size: 1rem;
      line-height: 1;
      text-align: left;
    }
  }

  .transformation {
    color: #B060FF;

    mat-icon {
      transform: scale(0.8);
    }

    span {
      font-weight: 600 !important;
      text-transform: uppercase;
      font-size: 1rem;
      line-height: 1;
      text-align: left;
    }
  }

  .environmental-factor {
    color: #8CCC59;

    mat-icon {
      transform: scale(0.8);
    }

    span {
      font-weight: 600 !important;
      text-transform: uppercase;
      font-size: 1rem;
      line-height: 1;
      text-align: left;
    }
  }
}

// core colors

@g-magenta--light: #fde8f3;
@g-magenta: #E83F98;
@g-magenta--dark: #7c0c46;

@g-gold--light: #ffedb6;
@g-gold: #FFD95F;
@g-gold--dark: #ba9d45;

@g-orange--light: #fceae2;
@g-orange: #EE7E50;
@g-orange--dark: #a55737;

@g-blue--light: #d5f2ff;
@g-blue: #2E9FFE;
@g-blue--dark: #3f7388;

@grey--dark-1: #1E1F20;
@grey--dark-2: #3c4042;
@grey--light-1: #7d8b92;
@grey--light-2: #e9e9e9;

@black: #000000;
@white: #FFFFFF;
@green: #37b732;
@red: #c6363b;

@g-gradient: linear-gradient(120deg, @g-magenta, 60%, @g-gold);

// Semantic Colors
@color--success: @green;
@color--info: @g-blue;
@color--warning: @red;
@color--danger: @red;

// Typography
@type--dark: @black;
@type--medium: @grey--dark-2;
@type--light: @grey--light-1;
@type--white: @white;
@type--accent: @g-blue;
@type--link: @g-blue;
@type--success: @color--success;
@type--info: @color--info;
@type--warning: @color--warning;
@type--danger: @color--danger;

// Icon Colors
@icon--dark: @black;
@icon--white: @white;
@icon--accent: @g-gold;
@icon--success: @color--success;
@icon--info: @color--info;
@icon--warning: @color--warning;
@icon--danger: @color--danger;

// Buttons
@button--primary-background: @g-blue;
@button--primary-border: @g-blue;
@button--primary-text-color: @white;

@button--primary-hover-background: @g-blue--light;
@button--primary-hover-border: @g-blue--light;
@button--primary-hover-text-color: @white;

@button-primary-disabled-background: @grey--light-1;

// Layout
@background--white: @white;
@background--light-alt: @g-blue--light;
@background--light: @grey--light-2;
@background--dark: @grey--dark-2;

// Others (to delete)

@light_gray: #e9e9e9;
@light_g_blue: #d5f2ff;
@medium_g_blue: #8ACEF5;



@box_shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);

@soft_border_radius: 10px;

@max_container_width: 750px;

@field_vertical_margin: 40px;

@normal_font_size: 0.65em;
@small_font_size: 0.5em;
@nav_link_font_size: 0.5em;
@option_font_size: 0.6em;
@mobile_normal_font_size: 0.7em;
@question_header_font_size: 1.2em;
@mobile_question_header_font_size: 1em;

@media_screen_width: 650px;

@thin_border: 0.25px solid #ECECEC;

.quick-transition {
  -o-transition: 0.25s;
  -ms-transition: 0.25s;
  -moz-transition: 0.25s;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}
